@import "./forms.scss",
        "./variables.scss",
        "./colors.scss";

.css-var-r0 {
    --ant-font-family:  'LatoWebRegular', sans-serif;
    --ant-font-family-code: 'LatoWebRegular', sans-serif;
    --ant-border-radius: 0px;
    --ant-color-primary: #{$base-color};
    --ant-color-primary-hover: #{$border-dark};
}

.css-var-r0.eff-menu-css-var {
--ant-menu-item-selected-bg: #edf1ff;
--ant-menu-item-selected-color: $base-color;
}

.eff-card {
    border-radius: 0px;
     box-shadow:
    inset 0 0em 0em rgb(0 200 0 / 30%),
    0 0 0 2px $white,
    0.3em 0.3em 1em rgba(104, 103, 103, 0.6);
}

// want to show card like error message color theme
.eff-card .hightp1 {
    cursor: pointer;
    .eff-card-head {
        color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    text-align: center;
    }
    .eff-card-body {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        border-top: 1px solid lighten($color: #721c24, $amount: 50);
    }
}
.eff-card .lowp1 {
    cursor: pointer;
    .eff-card-head {
        color: #155724;
        background-color: #d4edda;
        text-align: center;
       
    }
    .eff-card-body {
        color: #155724;
        background-color: #d4edda;
        border-top: 1px solid lighten($color: #155724, $amount: 50);
        
    }
}


.efficient-theme-light-menu {
    .leftMenuItem {
        border-radius: 0;
        border-left: 5px solid transparent;
    }
    .eff-menu-item-selected {
        border-left: 5px solid $secondary-color;
    }
}

.eff-menu-light:not(.eff-menu-horizontal) .eff-menu-submenu-title:hover,
.eff-menu-light>.eff-menu:not(.eff-menu-horizontal) .eff-menu-submenu-title:hover,
.eff-menu-light:not(.eff-menu-horizontal) .eff-menu-item:not(.eff-menu-item-selected):hover,
.eff-menu-light>.eff-menu:not(.eff-menu-horizontal) .eff-menu-item:not(.eff-menu-item-selected):hover {
    background-color: transparent;
    border-radius: 0;
    /* border-left: 5px solid transparent; */
}
.eff-menu-submenu-active,.eff-menu-active {
    background-color: $hover-color;
}
.efficient-theme-light-layout  {
    .eff-layout-sider {
        box-shadow: 10px 0 5px -2px #efefef;
    }
}
.leftPanelMenu {
    .slidePanelLogoBox {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slidePanelLogoCollapsed {
        display: none;
    }
}
.eff-layout-sider-collapsed {
    .slidePanelLogoCollapsed {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        span {
            background: url(../public/assets/images/logo_Icon.png);
            background-size: contain !important;
            background-repeat: no-repeat;
            width: 40px;
            position: relative;
            height: 40px;
            top: -3px;
        }
    }
    .slidePanelLogoBox {
        display: none;
    }
}
.loginPage .eff-form-item-control-input input{
    height: 40px;
    padding-left: 5px;
}
.loginPage .eff-input-affix-wrapper >input.eff-input {
    font-size: 16px !important;
}
.loginPage input.eff-input{
    border-radius: 10;
    font-size: 16px !important;
}

.loginPage .login-form-button {
    width: 100px;
    height: 40px;
    font-size: 16px;
}
.login-form-forgot {
    display: block;
}
.loginPage .eff-form-item-control-input  .eff-input-affix-wrapper {
    padding: 0;
   
}

.loginPage .eff-input-affix-wrapper .eff-input-prefix  {
    background-color: var(--ant-color-primary);
}
.loginPage .login-form-forgot {
   color: rgb(102, 102, 102) !important;
   font-size: 16px;
   margin-left: -80px;
}

div.editableonhover:hover {
    border:1px solid #eaeaea;
}
div.editableonhover {
    padding: 5px;
    max-width: 300px;
    height: 30px;
}

input.editableonhover {
    border:1px solid #eaeaea;
    padding: 5px;
    max-width: 300px;
    height: 30px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}

.overFlowX {
    overflow-x: auto;
}
.mrl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.rightHeaderIcons {
    position: absolute;
    right: 20px;
    z-index: 1;
    .eff-space-item {
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
    }
}
.mt-3 {
    margin-top: 1.3rem;
}
.amountField, .grandTotal{
    display: flex;
    align-items: center;
}
.amountField span, .grandTotal span{
    min-width: 100px;
}
.fontLarge {
    font-size: 1.2rem;
}
.grandTotalBox {
    padding-top: 10px;
    margin-top: 1.3rem;
    border-top: 1px solid #F40508;
    font-weight: 600;
}
@media screen and (max-width: 1200px){
    .dataTableMobileView {
        .eff-table-content {
            overflow-x: auto;
        }
        table {
            thead,tbody {
                tr {
                    td,th {
                        min-width: 150px;
                        &:first-child {
                            min-width: 80px;
                        }
                    }
                }
            }
        }
    }
    .ticketTable {
        table {
            thead,tbody {
                tr {
                    td,th {
                        min-width: 150px;
                        &:first-child {
                            min-width: 150px;
                        }
                        &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5),
                        :nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9) {
                            min-width:80px;
                        }
                    }
                }
            }
            thead {
                tr {
                    &:nth-child(2) {
                        th {
                           &:first-child {
                            min-width: 80px;
                           } 
                        }
                    }
                }
            }
        }
    }
    .amountField span, .grandTotal span{
        min-width: 120px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px){
    .topChartCard {
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1080px) {
    .loginContainer {
        min-width: 100%;
        background: none;
    }
    .leftPlane {
        display: none;
    }
    .topChartCard {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 768px) {
    .loginContainer {
        min-width: 100%;
    }
    .hideInMobileView {
        display: none;
    }
    .amountField,.grandTotal {
        min-width: 100%;
    }
    .amountField {
        margin-top: 10px;
    }
    .amountSummaryBox {
        .eff-row {
            flex-flow: column;
        }
    }
    .leftPlane {
        display: none;
    }
    .dataTableMobileView {
        table {
            thead,tbody {
                th {
                    display: none;
                }
                tr {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    min-width: calc(100vw - 65px);
                    td {
                        &:last-child {
                            border-bottom: 1px solid #F40508;
                        }
                    }
                  }
                td:nth-child(2n) {
                    grid-column: 1;
                }
            }
        }
    }
    .topChartCard {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .leftPanelMenu {
        position: absolute !important;
        z-index: 2;
        width: 100% !important;
        min-width: 80% !important;
        .eff-layout-sider-trigger {
            width: 40px !important;
            border: 1px solid;
            border-radius: 50%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 20px;
            right: 20px;
        }
        &.eff-layout-sider-collapsed {
            min-width: 80px !important;
            width: 80px !important;
            position: relative !important;
            display: none;
            .eff-layout-sider-trigger {
                position: absolute;
                top: unset;
                right: unset;
                bottom: 20px;
                width: 40px !important;
                left: 20px;
            }
            .slidePanelLogoCollapsed {
                display: none;
            }
        }
        .slidePanelLogoBox {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 20px 0;
            img {
                width: 50% !important;
            }
        }
    }
    .LogoMobileView {
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 100%;
        span {
            background: url(../public/assets/images/logo_Icon.png);
            background-size: contain !important;
            background-repeat: no-repeat;
            width: 40px;
            position: relative;
            height: 40px;
            top: -3px;
        }
    }
    .sliderButton {
        left: 60px;
        position: relative;
    }
    .position-relative {
        position: relative;
    }
    .userContentBox {
        .eff-card-head {
            .eff-card-head-wrapper {
                flex-wrap: wrap;
            }
            .eff-card-head-title {
                flex: none;
            }
        }
    }
    .userDetailBox {
        .userItem {
            width: 50%;
            &.bottomButton {
                margin: 2rem 0 1rem 0;            
                width: 100px;
                .eff-btn {
                    width: 100%;
                }
            }
            &.rolesBox {
                width: 100px;
                margin: 2rem 0 1rem 0;
            }
        }
        .formField {
            width: 95%;
            margin-top: 0.5rem;
            .eff-form-item-label {
                text-align: left;
                min-width: 80px;
            }

        }
    }
    .ticketTable {
        table {
            thead,tbody {
                tr {
                    td,th {
                        min-width: 150px;
                        &:first-child {
                            min-width: 150px;
                        }
                        &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5),
                        :nth-child(6), &:nth-child(7), &:nth-child(8), &:nth-child(9) {
                            min-width:80px;
                        }
                    }
                }
            }
        }
    }
    .doenloadPDFModal,.uploadPDFModal {
        .eff-input-number {
            width: 100%;
        }
        .mobileViewFullScreen {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .uploadPDFModal {
        .eff-picker-outlined {
            width: 100%;
        }
    }
}
