$primary-color:  #061d7e;
$base-color: #061d7e;
$border-dark: rgba($base-color, 0.88);
$secondary-color: #F40508;
$white: #fff;
$hover-color: #edf1ff;
$grey-5: #ccc;
$placeholder-color: #666666;
$text-color: #333333;
$green: #69AD3D;
$active: #69AD3D;
$light-grey: #999999;
$border-color:#C1C1C1;
$placeholder-color: #F4F4F4;
$light-background: #F8F8F8;
$wizard-circle: #707070;
$transparent: transparent;
$dropdown-border: #eaeaea;
$light-border-color: #e8e8e8;
$light-border: #e3e3e3;
$dropdown-border: #eaeaea;
$input-hover: #f3f3f3;
$input-hover: #f3f3f3;
$grey-400: #f1f1f1;
$even-row: #f9f9f9;