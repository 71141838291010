@import "colors",
        "variables";
.filterTextfieldBox {
    width: 22%;
    .eff-form-item-row {
        flex-wrap: wrap;
        label {
            line-height: 1;
            height: auto;
            color: $placeholder-color;
        }
        .eff-form-item-control {
            width: 100%;
            .searchInputField {
                height: 40px;
            }
        }
        .eff-picker-outlined {
            width: 100%;
            height: 40px;
        }
        input {
            height: 35px;
            color: $text-color;
        }
        .eff-select-outlined {
            width: 100%;
            height: 40px;
        }
    }
}
.filterClearBox {
    align-items: flex-end;
    display: flex;
    button {
        border-radius: 0;
        color: $placeholder-color;
        background: $grey-5;
        height: 40px;
        width: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .eff-btn-icon {
            .anticon {
                font-size: $font18;
            }
        }
    }
}