body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



html, body {
  padding: 0;
  margin: 0;
  background: #fff;
}

#root {
  padding: 0px;
}

.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.ant-select-item-option-content {
  white-space: break-spaces !important;
  word-break: break-word !important;
}

.disabled {
  pointer-events: none; 
  opacity: 0.6; 
}
.actionbuttoncontainer {
  display: flex;
  height: 30px;
}

.actionbuttoncontainer a {
  display: block;
  padding: 0px 10px;
  text-align: center;
  border-right: 1px solid #eee;
}
.actionbuttoncontainer a:nth-child() {
  border-right: 0px;
}

.eff-table-tbody .eff-table-row.error {
  background-color: #ffcbcb;
}

.visadetailinfo input, .visadetailinfo .editableonhover {
  width: 100%;
}

.morecontent {
  margin: 0;
  line-height: 14px;
}

/* OrganizationHierarchy.css */

.org-tree {
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}

.org-tree ul {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  padding-top: 20px;
  position: relative;
  transition: all 0.5s;
  list-style-type: none;
}

.org-tree li {
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;
  transition: all 0.5s;
}

.org-tree li::before, .org-tree li::after {
  content: '';
  position: absolute;
  top: 20px;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}

.org-tree li::after {
  right: auto; 
  left: 50%;
  border-left: 1px solid #ccc;
}

.org-tree li:only-child::after, .org-tree li:only-child::before {
  display: none;
}

.org-tree li:only-child {
  padding-top: 0;
}

.org-tree li:first-child::before, .org-tree li:last-child::after {
  border: 0 none;
}

.org-tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
}

.org-tree li:first-child::after {
  border-radius: 5px 0 0 0;
}

.org-tree .org-node {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: inline-block;
  background-color: #f9f9f9;
  transition: all 0.5s;
  font-family: Arial, sans-serif;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 120px;
  max-width: 200px;
}

.org-tree .org-node .name {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.org-tree .org-node .title {
  font-size: 14px;
  color: #888;
  margin-top: 4px;
}

.org-tree .org-node:hover {
  background-color: #e6f7ff;
  border-color: #91d5ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.org-tree ul ul::before {
  content: '';
  position: absolute; 
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 40px;
}

.td-description {
    width: 200px;
}

.td-description .editableonhover {
  height: auto;
} 

.td-description .eff-form-item {
  height: auto;
}