// border
$border-none: 0;
$border16: 16px;
$border14: 14px;
$border12: 12px;

//padding
$padding-none: 0;
$padding16: 16px;
$padding14: 14px;
$padding15: 15px;
$padding12: 12px;

//margin
$margin-none: 0;
$margin16: 16px;
$margin14: 14px;
$margiin12: 12px;

// letter spacing Zero
$letter-spacing: 0;

// Float's
$f-left: left;
$f-right: right;
$f-none: none;

// Font Size's
$font12: 0.75rem;
$font14: 0.875rem;
$font16: 1rem;
$font18: 1.125rem;
$font20: 1.25rem;
$font22: 1.375rem;
$font24: 1.5rem;
$font1: 1rem;

// Font Weight
$fw-bold: bold;
$fw-normal: normal;
$fw-700: 700;
$fw-600: 600;

// Width
$width-full: 100%;
$width-auto: auto;

.center {
  text-align: center;
}
